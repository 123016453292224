import { useEffect } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  FunctionField,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'ra-ui-materialui';
import { RichTextInput } from 'ra-input-rich-text';
import { required, FormDataConsumer, maxLength, useRecordContext } from 'react-admin';
import { RichTextInputCustomToolbar } from '../../toolbars/rich-text-input-toolbar';
import { imageInputAccept, S3ImageDir, SortingDirection } from '../../variables';
import { coinTypes } from '../../variables/coin-types';
import { InlineRow } from './styled';
import { useFormContext } from 'react-hook-form';
import { getRole } from '../../utils/get-role';
import { UserRole } from '../../variables/user-role';
import { getEnvs } from '../../env-lib';
import { shortDescriptionMaxLength } from './constants';
import { tradingViewExchanges } from '../../variables/trading-view-exchanges';
import { TrimOnBlurInput } from '../../inputs/trim-on-blur-input';
import { helperTexts } from '../../variables/helper-texts';
import { AppResourceNames } from '../../variables/app-resource-names';

const isAdmin = () => getRole() === UserRole.ADMIN;
const isDeveloper = () => getRole() === UserRole.DEVELOPER;
const imagesBasePath = getEnvs().REACT_APP_IMAGES_BASE_URL + S3ImageDir.COINS;

const MarketDataUnavailableInput = () => {
  const { setValue, formState, watch } = useFormContext();
  const record = useRecordContext();
  const priceUSD = watch('priceUSD');
  const source = 'marketDataNotAvailable';

  useEffect(() => {
    if (!record || !formState.defaultValues) {
      return;
    }

    setValue(source, Number(priceUSD) <= 0, { shouldDirty: true });
  }, [setValue, priceUSD, formState.defaultValues, record]);

  return <BooleanInput source={source} label="Data currently unavailable" defaultValue={true} />;
};

const CoinKeyInput = (props: { label: string; isEdit?: boolean }) => {
  const { label, isEdit } = props;

  return (
    <TextInput
      source="key"
      label={label}
      validate={required()}
      disabled={isEdit && !isAdmin() && !isDeveloper()}
      helperText={helperTexts.keyInputValidation}
    />
  );
};

const TokenPlatformInput = (props: { source: string; label: string }) => {
  const { source, label } = props;
  const record = useRecordContext();
  const isDisabled = !isAdmin() && Boolean(record?.[source]);
  return <TextInput source={source} label={label} disabled={isDisabled} />;
};

const CoinDescriptionSwitch = (props) => {
  const isDisabled = () => {
    if (getRole() === UserRole.MANAGER) {
      return !!props.record?.content?.isLockCoinDescription;
    }

    return !isAdmin();
  };

  return <BooleanInput label="Lock Coin Description" source="content.isLockCoinDescription" disabled={isDisabled()} />;
};

const SupplyInput = (props) => {
  const { setValue } = useFormContext();

  const sanitizeInput = (input) => {
    // Заменяем запятые на точки
    let sanitizedText = input.replace(/,/g, '.');
    // Удаляем все символы, кроме цифр и точки
    sanitizedText = sanitizedText.replace(/[^0-9.]/g, '');
    // Удаляем все точки, кроме первой
    sanitizedText = sanitizedText.replace(/\.(?=.*\.)/g, '');
    return sanitizedText;
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain');
    const sanitizedText = sanitizeInput(pastedText);
    setValue(props.source, sanitizedText, { shouldDirty: true });
    e.target.blur();
  };

  const handleChange = (e) => {
    const inputText = sanitizeInput(e.target.value);
    setValue(props.source, inputText, { shouldDirty: true });
  };

  return <TextInput {...props} onPaste={handlePaste} onChange={handleChange} />;
};

export const SummaryTab = (props) => {
  return (
    <TabbedForm.Tab label="Summary" {...props}>
      <BooleanInput source="enabled" />
      <MarketDataUnavailableInput />
      <BooleanInput source="showTradingView" label="Show trading view chart" defaultValue={true} />
      <TrimOnBlurInput source="name" label="Name" validate={required()} />
      <CoinKeyInput label="Key (Website slug)" isEdit={props.isEdit} />
      <FormDataConsumer>
        {({ formData }) => {
          const noToken = formData.type === 'no-token';
          return (
            <TextInput source="symbol" label="Symbol" disabled={noToken} validate={!noToken ? required() : undefined} />
          );
        }}
      </FormDataConsumer>
      <TokenPlatformInput source="tokenPlatformName" label="Blockchain Platform Name" />
      <TokenPlatformInput source="tokenPlatformKey" label="Blockchain Platform Key" />
      <FunctionField
        label="Image"
        render={(record) =>
          record.imageName ? (
            <img src={imagesBasePath + record.imageName} alt="image" style={{ maxHeight: '170px' }} />
          ) : null
        }
      />
      <ImageInput source="image" label="Image" accept={imageInputAccept}>
        <ImageField source="src" title="Image" />
      </ImageInput>
      <ReferenceInput
        label="CoinGecko Coin"
        source="coinGeckoCoinId"
        reference="coingecko-coins"
        resource="coingecko-coins"
        sort={{ field: 'name', order: SortingDirection.ASC }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="CoinMarketCap Coin"
        source="cmcCoinId"
        reference="coinmarketcap-coins"
        resource="coinmarketcap-coins"
        sort={{ field: 'name', order: SortingDirection.ASC }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="updateSupplyFromExternalAPI" label="Enable update supply (from Coingecko)" />
      <BooleanInput
        source="updateSupplyFromExternalAPIOnlyIfHigher"
        label="Update Supply Only above (from Coingecko)"
      />
      <BooleanInput source="ignoreNextPriceUpdateBlock" label="Ignore next price update blocking" />
      <DateInput source="disableServiceAlertsTill" sx={{ width: 200 }} label="Disable telegram price alerts till" />
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <InlineRow>
              <SupplyInput label="Circulating supply" source="availableSupply" />
              <SupplyInput label="Total supply" source="totalSupply" />
              <SupplyInput label="Max supply" source="maxSupply" disabled={formData?.unlimitedSupply} />
              <BooleanInput
                label="Unlimited Supply"
                source="unlimitedSupply"
                helperText="If you turn it on, then the max supply is reset"
              />
            </InlineRow>
          );
        }}
      </FormDataConsumer>
      <BooleanInput source="mineable" />
      <BooleanInput
        label="Calculate Percent From Total"
        source="calculatePercentFromTotal"
        helperText="If you turn it on then the % of circulating tokens will be calculated from the total supply not the max"
      />
      <ReferenceInput
        label="Category"
        source="categoryId"
        reference="coin-category"
        perPage={100}
        sort={{ field: 'name', order: SortingDirection.ASC }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <div>
        <ReferenceArrayInput
          source="tagIds"
          resource="tagIds"
          reference="coin-tag"
          sort={{ field: 'name', order: SortingDirection.ASC }}
          perPage={300}
        >
          <SelectArrayInput optionText="name" label="Tags" fullWidth />
        </ReferenceArrayInput>
      </div>
      <div>
        <ReferenceArrayInput
          source="ecosystemIds"
          reference={AppResourceNames.ECOSYSTEMS}
          sort={{ field: 'name', order: SortingDirection.ASC }}
          perPage={300}
        >
          <AutocompleteArrayInput optionText="name" label="Ecosystem" />
        </ReferenceArrayInput>
      </div>
      <div>
        <ReferenceArrayInput source="fundIds" reference="funds" sort={{ field: 'name', order: SortingDirection.ASC }}>
          <AutocompleteArrayInput optionText="name" label="Funds" fullWidth />
        </ReferenceArrayInput>
      </div>
      <SelectInput source="type" label="Type" choices={coinTypes} validate={[required()]} />
      <ReferenceInput label="Jurisdiction" source="countryId" reference="countries" perPage={10}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <ReferenceInput
            label="Stable Coin (select base)"
            source="stableCoinForId"
            reference="coins"
            resource="coins"
            filter={{ not_id: formData.id }}
            sort={{ field: 'id', order: SortingDirection.ASC }}
            {...rest}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        )}
      </FormDataConsumer>
      <SelectInput
        source="trading_view_exchange"
        label="TradingView Mapping"
        helperText="You can select specific exchange to display TradingView chart (if available on TradingView)"
        choices={tradingViewExchanges}
        sx={{ width: 550 }}
      />
      <TextInput
        source="trading_view_symbol"
        label="TradingView Symbol"
        helperText="You can specify token's symbol to search on TradingView in case TradingView has different symbol for this token. If empty existing token's symbol will be used."
        sx={{ width: 550 }}
      />
      <DateInput source="listing_date" sx={{ width: 200 }} helperText="This date will be displayed as a listing date" />
      <CoinDescriptionSwitch />
      <FormDataConsumer>
        {({ formData }) => (
          <RichTextInput
            label="What is?"
            source="content.description"
            toolbar={<RichTextInputCustomToolbar />}
            readOnly={formData?.content?.isLockCoinDescription ?? false}
            fullWidth={true}
          />
        )}
      </FormDataConsumer>
      <TextInput
        multiline={true}
        label="Short description"
        source="content.shortDescription"
        validate={maxLength(shortDescriptionMaxLength)}
        placeholder={`Maximum of ${shortDescriptionMaxLength} characters`}
        inputProps={{ maxLength: shortDescriptionMaxLength }}
      />
    </TabbedForm.Tab>
  );
};
